import axios from "axios";
import { format } from "date-fns";

const url = "/api/home";

export const DDC_ID = 2;
export const IS_DEBUG = false;

export const apiSocketRoutes = {
  homePage: !IS_DEBUG ? `https://cerera.lv` : `http://localhost:5000`,
};

export const apiRoutes = {
  homePage: !IS_DEBUG
    ? `https://cerera.lv${url}`
    : `http://localhost:5000${url}`,

  addCompanyEquipment: `${url}/add-company-equipment`,
  addEquipmentExamination: `${url}/add-equipment-examination`,
  addDocumentationSectionFile: `${url}/add-documentation-section-file`,
  addDocumentationSection: `${url}/add-documentation-section`,
  addCompanyAccidentRegister: `${url}/add-company-accident-register`,
  addCompanyAccidentProtocol: `${url}/add-company-accident-protocol`,
  addCompanyPaymentOption: `${url}/add-company-payment-option`,
  addCompanyPayment: `${url}/add-company-payment`,
  addCompanyDocumentation: `${url}/add-company-documentation`,
  addCompany: `${url}/add-company`,
  addWorker: `${url}/add-worker`,
  addOccupation: `${url}/add-occupation`,
  addBranch: `${url}/add-branch`,
  addRole: `${url}/add-role`,
  addInstruction: `${url}/add-instruction`,
  addInstructionFile: `${url}/add-instruction-file`,
  addRisk: `${url}/add-risk`,
  addProtocol: `${url}/add-protocol`,
  addProtocolImage: `${url}/add-protocol-image`,
  addPlanImage: `${url}/add-plan-image`,
  addMedicalCertificateFile: `${url}/add-medical-certificate-file`,
  addPlan: `${url}/add-plan`,
  addPlanFile: `${url}/add-plan-file`,
  addTest: `${url}/add-test`,
  addTestFile: `${url}/add-test-file`,
  addCourse: `${url}/add-course`,
  addCourseFile: `${url}/add-course-file`,
  addCertificateFile: `${url}/add-certificate-file`,
  addMedicalCertificateFile: `${url}/add-medical-certificate-file`,
  addFolder: `${url}/add-folder`,
  addFile: `${url}/add-file`,
  addSystemFile: `${url}/add-system-file`,
  addPartnerCompany: `${url}/add-partner-company`,
  addPartnerCompanyFile: `${url}/add-partner-company-file`,
  addPartnerCompanyRequiredFile: `${url}/add-partner-company-required-file`,
  addCompanyExcel: `${url}/add-company-excel-file`,
  addRiskAssesmentExcel: `${url}/add-risk-assesment-excel-file`,
  addUserRemoteJobTest: `${url}/add-user-remote-job-test`,
  addUserRemoteJobTestFile: `${url}/add-user-remote-job-test-file`,
  addUserLogin: `${url}/add-user-login`,
  addTemporaryPassword: `${url}/add-temporary-password`,
  addFileToSignData: `${url}/add-file-to-sign-data`,

  setDownloadData: `${url}/set-download-data`,
  editCompanyEquipment: `${url}/edit-company-equipment`,
  editEquipmentExamination: `${url}/edit-equipment-examination`,
  editFile: `${url}/edit-file`,
  editDocumentationSection: `${url}/edit-documentation-section`,
  editCompanyAccidentRegister: `${url}/edit-company-accident-register`,
  editCompanyAccidentProtocol: `${url}/edit-company-accident-protocol`,
  editCompanyDocumentation: `${url}/edit-company-documentation`,
  editCompany: `${url}/edit-company`,
  editCompanyEmail: `${url}/edit-company-email`,
  editDefaultEmail: `${url}/edit-default-email`,
  editWorker: `${url}/edit-worker`,
  editOccupation: `${url}/edit-occupation`,
  editBranch: `${url}/edit-branch`,
  editUserMedicalRestrictions: `${url}/edit-user-medical-restrictions`,
  editPartnerCompanyRequiredDocumentConfirmation: `${url}/edit-partner-company-required-document-confirmation`,
  editWorkerCompanyLastVisited: `${url}/edit-worker-company-last-visited`,
  editEquipmentSignatureDate: `${url}/edit-equipment-signature-date`,
  editBranchHierarchy: `${url}/edit-branch-hierarchy`,
  editRole: `${url}/edit-role`,
  editInstruction: `${url}/edit-instruction`,
  editProtocol: `${url}/edit-protocol`,
  editProtocolAnswers: `${url}/edit-protocol-answers`,
  editRiskAssesmentDate: `${url}/edit-risk-assesment-date`,
  confirmProtocolAnswer: `${url}/confirm-protocol-answer`,
  editPlan: `${url}/edit-plan`,
  editTest: `${url}/edit-test`,
  editCourse: `${url}/edit-course`,
  migrateBranches: `${url}/migrate-branches`,
  editPartnerCompany: `${url}/edit-partner-company`,
  editUserRemoteJobTest: `${url}/edit-user-remote-job-test`,
  resetTemporaryPassword: `${url}/reset-temporary-password`,
  initiateFileSign: `${url}/initiate-file-sign`,
  uploadSignedFile: `${url}/upload-signed-file`,
  uploadExcelCompanyEquipment: `${url}/upload-excel/equipment`,
  changeUserSignatureStatus: `${url}/change-user-signature-status`,
  changeExternalUserSignatureStatus: `${url}/change-external-user-signature-status`,
  updateCereraAdminStatus: `${url}/update-cerera-admin-status`,
  uploadCompanyPaymentFile: `${url}/upload-company-payment-file`,
  editCompanyPaymentOption: `${url}/edit-company-payment-option`,
  editCompanyPayment: `${url}/edit-company-payment`,
  editCompanyPaymentOptionStatus: `${url}/edit-company-payment-option-status`,
  moveFiles: `${url}/move-files`,

  login: `${url}/login`,
  verify: `${url}/verify`,
  logout: `${url}/logout`,

  getCompanyEquipment: `${url}/get-company-equipment`,
  getEquipmentExamination: `${url}/get-equipment-examination`,
  getEquipmentExaminationById: `${url}/get-equipment-examination-by-id`,
  getCompanyEquipmentList: `${url}/get-company-equipment-list`,
  getEquipmentExaminationList: `${url}/get-equipment-examination-list`,
  getDocumentationSection: `${url}/get-documentation-section`,
  getDocumentationSections: `${url}/get-documentation-sections`,
  getDocumentationSectionList: `${url}/get-documentation-section-list`,
  getUserBasics: `${url}/get-user-basics`,
  getUser: `${url}/get-user-full`,
  getUserMedicalRestrictions: `${url}/get-user-medical-restrictions`,
  getCompanyDocumentationList: `${url}/get-company-documentation-list`,
  getCompanyDocumentation: `${url}/get-company-documentation`,
  getUserCompanies: `${url}/get-user-companies`,
  getCourseLesson: `${url}/get-course-lesson`,
  getInstructionDescription: `${url}/get-instruction-description`,
  getTemporaryPasswords: `${url}/get-temporary-passwords`,
  getSpecialists: `${url}/get-specialists`,
  getCompanySpecialists: `${url}/get-company-specialists`,
  getAdministrationRoles: `${url}/get-administration-roles`,
  getAdministrationRoleList: `${url}/get-administration-role-list`,
  getWorkerList: `${url}/get-worker-list`,
  getCompanyAccidentRegisterList: `${url}/get-company-accident-register-list`,
  getCompanyAccidentProtocolList: `${url}/get-company-accident-protocol-list`,
  getCompanyAccidentRegister: `${url}/get-company-accident-register`,
  getCompanyAccidentProtocol: `${url}/get-company-accident-protocol`,
  getBranchList: `${url}/get-branch-list`,
  getBranches: `${url}/get-branches`,
  getRiskAssesments: `${url}/get-risk-assesments`,
  getWorkerOccupations: `${url}/get-worker-occupations`,
  getWorkerReadableRiskAssesments: `${url}/get-worker-readable-risk-assesments`,
  getWorkerBranches: `${url}/get-worker-branches`,
  getWorkerMedicalCertificates: `${url}/get-worker-medical-certificates`,
  getWorkerInstructions: `${url}/get-worker-instructions`,
  getWorkerInstruction: `${url}/get-worker-instruction`,
  getWorkerProfileDetails: `${url}/get-worker-profile-details`,
  getOccupations: `${url}/get-occupations`,
  getOccupationList: `${url}/get-occupation-list`,
  getRiskAssesmentList: `${url}/get-risk-assesment-list`,
  getWorkerCourses: `${url}/get-worker-courses`,
  getWorkerOutsideCourses: `${url}/get-worker-outside-courses`,
  getWorkerRemoteJobTest: `${url}/get-worker-remote-job-test`,
  getWorkerSignedFiles: `${url}/get-worker-signed-files`,
  getNotifications: `${url}/get-notifications`,
  getNotificationCount: `${url}/get-notification-count`,
  getCourseList: `${url}/get-course-list`,
  getCourses: `${url}/get-courses`,
  getCourse: `${url}/get-course`,
  getOccupationDetails: `${url}/get-occupation-details`,
  getOccupationInstructions: `${url}/get-occupation-instructions`,
  getOccupationCourses: `${url}/get-occupation-courses`,
  getOccupationOutsideCourses: `${url}/get-occupation-outside-courses`,
  getPlanList: `${url}/get-plan-list`,
  getPlans: `${url}/get-plans`,
  getPlan: `${url}/get-plan`,
  getFileList: `${url}/get-file-list`,
  getFileData: `${url}/get-file-data`,
  getInstructionList: `${url}/get-instruction-list`,
  getInstructions: `${url}/get-instructions`,
  getTemplateOccupations: `${url}/get-template-occupations`,
  getTemplatePlans: `${url}/get-template-plans`,
  getInstruction: `${url}/get-instruction`,
  getFactors: `${url}/get-factors`,
  getTemplateRiskAssesment: `${url}/get-template-risk-assesment`,
  getPartnerCompanyList: `${url}/get-partner-company-list`,
  getPartnerCompanies: `${url}/get-partner-companies`,
  getPartnerCompany: `${url}/get-partner-company`,
  getUserSignedFileList: `${url}/get-user-signed-file-list`,
  getProtocolAnswerList: `${url}/get-protocol-answer-list`,
  getProtocolAnswersIndividual: `${url}/get-protocol-answers-individual`,
  getProtocolBranchList: `${url}/get-protocol-branch-list`,
  getProtocolList: `${url}/get-protocol-list`,
  getProtocolTemplatesList: `${url}/get-protocol-templates-list`,
  getTestTemplatesList: `${url}/get-test-templates-list`,
  getProtocol: `${url}/get-protocol`,
  getProtocolWorkers: `${url}/get-protocol-workers`,
  getArchive: `${url}/get-archive`,
  getArchiveList: `${url}/get-archive-list`,
  getArchiveCount: `${url}/get-archive-count`,
  getTests: `${url}/get-tests`,
  getWorkerPartnerDocuments: `${url}/get-worker-partner-documents`,
  getWorkerCompanies: `${url}/get-worker-companies`,
  getWorkerRecentlyVisitedCompanies: `${url}/get-worker-recently-visited-companies`,
  getWorkerCompaniesList: `${url}/get-worker-companies-list`,
  getAdministrationCompaniesList: `${url}/get-administration-companies-list`,
  getAdministrationCompanies: `${url}/get-administration-companies`,
  getAdministrationCompanyDetails: `${url}/get-administration-company-details`,
  getAdministrationCompanyRiskProvider: `${url}/get-administration-company-risk-provider`,
  getAdministrationRiskProviderData: `${url}/get-administration-risk-provider-data`,
  getAdministrationCompanyPaymentDetails: `${url}/get-administration-company-payment-details`,
  getAdministratorsList: `${url}/get-administrators-list`,
  getAdministratorProfileData: `${url}/get-administrator-profile-data`,
  getCompanyEmails: `${url}/get-company-emails`,
  getDefaultEmails: `${url}/get-default-emails`,
  getCompanyPaymentOptions: `${url}/get-company-payment-options`,
  getCompanyPayments: `${url}/get-company-payments`,
  getCompanyPaymentsList: `${url}/get-company-payments-list`,

  downloadDocumentationImage: `${url}/download/documentation-image`,
  downloadCompanyUsedEquipmentList: `download/company-used-equipment-list`,
  downloadDocumentation: `download/documentation`,
  downloadCompanyAccidentRegister: `download/company-accident-register`,
  downloadCompanyAccidentProtocol: `download/company-accident-protocol`,
  downloadCompanyAccidentOrder: `download/company-accident-order`,
  downloadFile: `download/file`,
  downloadSystemFile: `download/system-file`,
  downloadRisks: `download/risks`,
  downloadRiskMethodics: `download/risk-methodics`,
  downloadEquipmentList: `download/equipment-list`,
  downloadEquipmentExam: `download/equipment-exam`,
  downloadEquipmentListForPersonnel: `download/equipment-list-for-personel`,
  downloadExistingCourseByName: `download/courses`,
  downloadExistingInstructionByName: `download/instructions`,
  downloadExistingPlanPicture: `download/plan-picture`,
  downloadExistingProtocolPicture: `download/protocol-picture`,
  downloadExistingCertificate: `download/existing-certificate`,
  downloadExistingTestFile: `download/existing-test-file`,
  downloadExistingMedicalCertificate: `download/existing-medical-certificate`,
  downloadInstructionGraph: `download/instruction-graph`,
  downloadInstructionList: `download/instruction-list`,
  downloadPlans: `download/plans`,
  downloadProtocols: `download/protocols`,
  downloadDefaultSignatureJournalEmpty: `download/default-signature-journal-empty`,
  downloadFireSignatureJournalEmpty: `download/fire-signature-journal-empty`,
  downloadSignatureJournalEmpty: `download/signature-journal-empty`,
  downloadExcelCompanyUploadTemplate: `download-excel-company-upload-template`,
  downloadExcelCompanyEquipmentTemplate: `download-excel-equipment-upload-template`,
  downloadExcelBranchUploadTemplate: `download-excel-branch-upload-template`,
  downloadExcelOccupationUploadTemplate: `download-excel-occupation-upload-template`,
  downloadMedicalCertificates: `download/medical-certificates`,
  downloadMedicalCertificatesOccupations: `download/medical-certificate-occupations`,
  downloadSignatureJournal: `download/signature-journal`,
  downloadPartnerDocument: `download/partner-document`,
  downloadMedicalCertificateList: `download/medical-certificate-list`,
  downloadUserMedicalCertificateList: `download/user-medical-certificate-list`,
  downloadWorkerListFile: `download/worker-list-file`,
  downloadUserInstructionList: `download/user-instruction-list`,
  downloadUserCourseList: `download/user-course-list`,
  downloadUserOutsideCourseList: `download/user-outside-course-list`,
  downloadUserRemoteJobTestFile: `download/user-remote-job-test-file`,
  downloadUserSignedFile: `download/user-signed-file`,
  downloadBranchWorkerListFile: `download/branch-worker-list-file`,
  downloadRemoteJobTestResultIndividual: `download/remote-job-test-result-individual`,
  downloadRemoteJobTestResultGlobal: `download/remote-job-test-result-global`,
  downloadTestResultIndividual: `download/test-results-individual`,
  downloadTestResultGlobal: `download/test-results-global`,
  downloadWorkerReport: `download/worker-report`,
  downloadOutsideCourseCard: `download/outside-course-card`,
  downloadCompanyPaymentInvoice: `download/company-payment-invoice`,
  downloadCompanyPayment: `download/company-payment`,
  downloadWorkerMedicalFactorListFile: `download/worker-medical-factor-list-file`,

  sendUsersToExamination: `${url}/mailing-medical-certificate`,
  sendUsersInstructions: `${url}/mailing-instruct`,
  sendUsersSignatureJournal: `${url}/mailing-signature-journals`,
  sendFiles: `${url}/mailing-send-files`,
  sendHomeApplication: `${url}/mailing-send-home-application`,
  sendOutsideCourseRegistration: `${url}/mailing-send-outside-course-registration`,
  sendTemporaryPassword: `${url}/mailing-send-temporary-password`,
  sendSignedFileNotification: `${url}/mailing-send-signed-file-notification`,
  sendCustomMail: `${url}/mailing-send-custom-mail`,
  sendProtocolNotification: `${url}/mailing-send-protocol-notification`,
  sendCustomMessage: `${url}/messenger-send-custom-message`,

  registerMedicalCertificate: `${url}/register-medical-certificate`,
  registerCertificate: `${url}/register-certificate`,
  registerAccident: `${url}/register-accident`,
  registerInstructions: `${url}/register-instructions`,
  registerCourses: `${url}/register-courses`,
  registerUserReadInstructions: `${url}/register-read-instruction`,
  registerCourseTestResult: `${url}/register-course-test-result`,
  registerInstructionTestResult: `${url}/register-instruction-test-result`,
  registerUserReadRiskAssesment: `${url}/register-read-risk-assesment`,
  registerUserSignature: `${url}/register-signature`,
  registerReadPartnerDocument: `${url}/register-read-partner-document`,

  voidUserInstruction: `${url}/void-instructions`,
  voidCourses: `${url}/void-courses`,

  deleteCompanyEquipment: `${url}/delete-company-equipment`,
  deleteEquipmentExamination: `${url}/delete-equipment-examination`,
  deleteDocumentationSection: `${url}/delete-documentation-section`,
  deleteCompanyAccidentRegister: `${url}/delete-company-accident-register`,
  deleteCompanyAccidentProtocol: `${url}/delete-company-accident-protocol`,
  deleteCompanyPaymentOption: `${url}/delete-company-payment-option`,
  deleteCompanyPayment: `${url}/delete-company-payment`,
  deleteCompanyDocumentations: `${url}/delete-company-documentations`,
  deleteCompany: `${url}/delete-company`,
  deleteWorkers: `${url}/delete-workers`,
  deleteOccupations: `${url}/delete-occupations`,
  deleteInstructions: `${url}/delete-instructions`,
  deleteRiskAssesments: `${url}/delete-risk-assesments`,
  deleteProtocolImage: `${url}/delete-protocol-image`,
  deleteProtocols: `${url}/delete-protocols`,
  deleteProtocolPages: `${url}/delete-protocol-pages`,
  deletePlans: `${url}/delete-plans`,
  deleteBranches: `${url}/delete-branches`,
  deleteRoles: `${url}/delete-roles`,
  deleteCourses: `${url}/delete-courses`,
  deleteFile: `${url}/delete-file`,
  deletePartnerCompanyDocument: `${url}/delete-partner-company-document`,
  deletePartnerCompanyRequiredDocument: `${url}/delete-partner-company-required-document`,
  deletePartnerCompanies: `${url}/delete-partner-companies`,
  deleteTemporaryPasswords: `${url}/delete-temporary-passwords`,
  deleteUserSignedFiles: `${url}/delete-user-signed-files`,
  deleteUserRemoteJobTest: `${url}/delete-user-remote-job-test`,

  validatePhoneNumber: `${url}/validate-phone-number`,
  serverStatus: `${url}/server-status`,
  validateJWTToken: `${url}/validate-jwt-token`,
  validateFileSigning: `${url}/validate-file-signing`,
};

export const apiHeaders = (additionalHeaders = {}, params) => {
  const today = new Date();
  const formattedDateTime = format(today, "dd/MM/yyyy HH:mm:ss");

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + sessionStorage.token,
    userId: sessionStorage.getItem("user_id") ?? "",
    companyId: sessionStorage.getItem("currentCompanyId") ?? "",
    actionDate: formattedDateTime,
    ...additionalHeaders,
  };

  params = {
    request_company_id: sessionStorage.getItem("currentCompanyId") ?? "",
    request_user_id: sessionStorage.getItem("user_id") ?? "",
    ...params,
  };

  return { headers, params: params };
};

export const apiDownloadFile = async (url, payload = {}, params = {}) => {
  const user_id = sessionStorage.getItem("user_id");
  const company_id = sessionStorage.getItem("currentCompanyId");
  await axios
    .post(
      apiRoutes.setDownloadData,
      JSON.stringify({ user_id: user_id, company_id: company_id, ...payload }),
      apiHeaders(params, {})
    )
    .then((response) => {
      window.open(`${apiRoutes.homePage}/${url}/${user_id}/${response.data}`);
    });
};

export const getFileURL = async (url, payload = {}, params = {}) => {
  let id = "";
  const user_id = sessionStorage.getItem("user_id");
  const company_id = sessionStorage.getItem("currentCompanyId");


  await axios
    .post(
      apiRoutes.setDownloadData,
      JSON.stringify({ user_id: user_id, company_id: company_id, ...payload }),
      apiHeaders(params)
    )
    .then((response) => {
      id = response.data;
    });

  return `${apiRoutes.homePage}/${url}/${user_id}/${id}`;
};

export const uploadSystemFile = async (file, sys_tag = "") => {
  const formData = new FormData();
  formData.append("file", file);

  return await axios.post(
    apiRoutes.addSystemFile,
    formData,
    apiHeaders({ "Content-Type": "multipart/form-data" }, { sys_tag: sys_tag })
  );
};
